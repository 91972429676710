@keyframes rotate {
  0% {
    transform-origin: center;
    opacity: 1;
  }

  to {
    transform-origin: center;
    transform: rotate(3600deg);
    opacity: 0;
  }
}

.rotate {
  animation: rotate 1s ease-in;
}
