html {
  background: rgb(240, 240, 240) 100%;
}
body {
  background: radial-gradient(
    circle at center,
    rgb(255, 255, 255) 0,
    rgb(240, 240, 240) 100%
  );
}

.btn-outline-dark {
  background-color: white;
}

.btn-outline-dark:hover {
  background-color: #212529;
}

.profile-container {
  margin-bottom: 15px;
}

.profile-border-thin {
  width: 100%;
  padding-bottom: calc(100% - 2px);
  z-index: 100;
  border: 1px solid #212529;
  -webkit-transform: translate3d(0, 0, 0);
  user-select: none;
  pointer-events: none;
}

.profile-border-fat {
  width: calc(100% + 10px);
  top: -5px;
  left: -5px;
  z-index: 50;
  padding-bottom: 100%;
  border: 5px solid #fff;
  pointer-events: none;
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  html {
    background: rgb(0, 0, 0) 100%;
  }
  body {
    background: radial-gradient(
      circle at center,
      rgb(30, 30, 30) 0,
      rgb(0, 0, 0) 100%
    );
    color: white;
  }
  .btn-outline-dark {
    background-color: transparent;
    color: white;
    border-color: white;
  }
  .btn-outline-dark:hover {
    color: #212529;
    background-color: white;
  }
  .profile-border-thin {
    border-color: white;
  }
  .profile-border-fat {
    border-color: #202020;
  }
}
